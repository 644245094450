<template>
	<div id="title" style="margin: 10px;">
		<p>
			<span>示例：</span>
		<pre>
	69414068756xx
	69414068756xx
	69414068756xx
				</pre>
		</p>

		<div>
			<textarea v-model="goodsData" rows="15" cols="200"></textarea>
			<p>
				<el-button @click="exportGoods" type="primary">导出</el-button>
			</p>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="10%" :before-close="handleClose">
			<span>文件读取中...<i class="el-icon-loading"></i></span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">完成</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				goodsData: ""
			}
		},
		created() {

		},
		methods: {
			exportGoods() {
				let goodsStrs = this.goodsData
				let goodsArr = goodsStrs.split("\n")
				if (goodsArr.length > 0) {

					this.dialogVisible = true
					let postData = {
						"goods": goodsArr
					}
					this.$axios.post("/a1/order/downCredential", postData).then((res) => {

						let dowloadKey = res.data.data.to_key;
						//开始下载
						location.href = this.$root.DownUrl + "/c1/down/" + dowloadKey;
						//this.dialogVisible = false
					}).catch((err) => {
						this.$messages.success(err.response.data.data)
					})
				} else {
					this.$messages.error("文件修改失效")
				}



			},
			handleClose(done) {
				done();
			}
		},
	}
</script>

<style scoped>

</style>
